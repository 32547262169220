import axios from "axios";
import { toast } from "react-toastify";
import { criarInterceptadores } from "@koboldbr/react-kob/api/interceptadores";

/**
 * Função responsável por criar uma instância do client axios com as configurações necessárias para realizar requisições à API.
 * @returns {import("axios").AxiosInstance} Instância do axios configurada com o token de autenticação e interceptores para tratamento de erros.
 */
const api = (baseURL) => {
    const token = localStorage.getItem("token");

    let axiosInstance = axios.create({
        baseURL: baseURL ?? window.AuthWebSettings.AuthApi,
        headers: { Authorization: `Bearer ${token}` },
    });

    criarInterceptadores(axiosInstance, toast);

    return axiosInstance;
};

export default api;
