import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import style from './naoAutenticado.module.scss'
import LogoLogin from "../images/logo-login.png"
import {Link} from "react-router-dom";

export class NaoAutenticado extends Component {
    static displayName = NaoAutenticado.name;

    render() {
        return (
            <>
            <div className={style['naoAutenticado']}>
                <Container>
                    <Row>
                        <Col xs={{ span: 3, offset: 1 }}>
                                <div className={style['login__logo']}>
                                    <img src={LogoLogin} alt='Logo' />
                                </div>
                        </Col>
                        <Col >
                            <div>
                                <h1>Ops! Parece que você não está autenticado :(</h1>
                                <span>
                                <Link to="/login"> Clique aqui para fazer login </Link>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
        );
    }
}
