import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import ReactDOMClient from "react-dom/client";
import { AuthProvider } from './contexts/AuthContext';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

// Create a root.
const root = ReactDOMClient.createRoot(rootElement);

root.render(
  <BrowserRouter basename={baseUrl}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>
);

registerServiceWorker();
