import { lazy, Suspense, useEffect } from 'react';
import { Outlet } from "react-router-dom";
import {TailSpin} from "react-loader-spinner";
import { NaoAutenticado } from './pages/NaoAutenticado/NaoAutenticado';
import { useUser } from './hooks';

const LoginPage = lazy(() => import('./pages/Login/login'))

export const PrivateRoute = () => {
  const { contentName, consultarPerfilDoUsuario } = useUser();

  useEffect(() => {
    consultarPerfilDoUsuario()
  }, [consultarPerfilDoUsuario])

  if (localStorage.getItem("token") ) {
    let content = '';
    switch (contentName) {
      case 'autenticado':
          content = <Outlet />
          break;
      case 'nao-autenticado':
          content = <NaoAutenticado/>
          break;
      default:
          content = (
              <div style={{position: 'fixed', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
                  <TailSpin color='#0090A1' height={"100px"} wrapperStyle={{alignItems: "center", justifyContent: "center"}}/>
              </div>
          )
          break;
  }
    return content;
  } else {
      return <Suspense fallback={<TailSpin color='#0090A1' height={"100px"} wrapperStyle={{alignItems: "center", justifyContent: "center"}}/>}> <LoginPage/> </Suspense> 
  }
};
