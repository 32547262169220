import React, { createContext, useState, useCallback } from "react";
import { consultarProfile } from "../js/apis/perfil/profile";
import MODULOS from "../utils/enumModulos";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [usuario, setUsuario] = useState({});
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [contentName, setContentName] = useState("");
  const [colaboracoesCAP, setColaboracoesCAP] = useState([]);
  const [colaboracoesCAR, setColaboracoesCAR] = useState([]);

  const consultarPerfilDoUsuario = useCallback(async () => {
    setLoadingProfile(true);
    try {
      const usuario = await consultarProfile();
      if (usuario) {
        setUsuario(usuario);
        setContentName("autenticado");
        const usuarioPossuiColaboracao = usuario.colaboracao.length > 0;

        if (usuarioPossuiColaboracao) {
          setColaboracoesCAP(
            usuario.colaboracao
              .filter((colab) => colab.modulos.includes(MODULOS.ContasAPagar))
              .map((colab) => ({
                ...colab,
                url: `${window.AuthWebSettings.CapWeb}/${colab.cnpj}/compras`,
              }))
          );
          setColaboracoesCAR(
            usuario.colaboracao
              .filter((colab) => colab.modulos.includes(MODULOS.ContasAReceber))
              .map((colab) => ({
                ...colab,
                url: `${window.AuthWebSettings.CarWeb}/${colab.cnpj}/vendas`,
              }))
          );
        }
      }
    } catch (error) {
      setContentName("nao-autenticado");
    } finally {
      setLoadingProfile(false);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        usuario,
        setUsuario,
        consultarPerfilDoUsuario,
        loadingProfile,
        contentName,
        colaboracoesCAP,
        colaboracoesCAR,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
